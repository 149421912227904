@font-face {
  font-family: Hermes;
  src: url("Hermes-Regular.2b12006f.eot");
  src: url("Hermes-Regular.2b12006f.eot#iefix") format("embedded-opentype"), url("Hermes-Regular.650fb2e7.woff2") format("woff2"), url("Hermes-Regular.dde2b24b.woff") format("woff"), url("Hermes-Regular.b20b7444.ttf") format("truetype"), url("Hermes-Regular.8747794c.svg#Hermes-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Hermes;
  src: url("Hermes-Bold.56b95db2.eot");
  src: url("Hermes-Bold.56b95db2.eot#iefix") format("embedded-opentype"), url("Hermes-Bold.2d5bd705.woff2") format("woff2"), url("Hermes-Bold.4485ab38.woff") format("woff"), url("Hermes-Bold.c5bd069d.ttf") format("truetype"), url("Hermes-Bold.244e9902.svg#Hermes-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Hermes;
  src: url("Hermes-Thin.8de25035.eot");
  src: url("Hermes-Thin.8de25035.eot#iefix") format("embedded-opentype"), url("Hermes-Thin.d1e96354.woff2") format("woff2"), url("Hermes-Thin.6da60f59.woff") format("woff"), url("Hermes-Thin.8240ea1d.ttf") format("truetype"), url("Hermes-Thin.b6f7b553.svg#Hermes-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/*# sourceMappingURL=index.2c44a9ad.css.map */
