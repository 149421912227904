@font-face {
    font-family: 'Hermes';
    src: url('Hermes-Regular.eot');
    src: url('Hermes-Regular.eot?#iefix') format('embedded-opentype'),
        url('Hermes-Regular.woff2') format('woff2'),
        url('Hermes-Regular.woff') format('woff'),
        url('Hermes-Regular.ttf') format('truetype'),
        url('Hermes-Regular.svg#Hermes-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hermes';
    src: url('Hermes-Bold.eot');
    src: url('Hermes-Bold.eot?#iefix') format('embedded-opentype'),
        url('Hermes-Bold.woff2') format('woff2'),
        url('Hermes-Bold.woff') format('woff'),
        url('Hermes-Bold.ttf') format('truetype'),
        url('Hermes-Bold.svg#Hermes-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hermes';
    src: url('Hermes-Thin.eot');
    src: url('Hermes-Thin.eot?#iefix') format('embedded-opentype'),
        url('Hermes-Thin.woff2') format('woff2'),
        url('Hermes-Thin.woff') format('woff'),
        url('Hermes-Thin.ttf') format('truetype'),
        url('Hermes-Thin.svg#Hermes-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

